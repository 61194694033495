// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}
