// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Alert
\* ------------------------------------------------------------------------ */

.alert-block {
    & {
        @include layer("alert");
    }

    & {
        background: color("error");
        display: none;
        padding: remify($content-padding);
    }

    &.is-active {
        display: block;
    }
}

.alert__inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.alert__button {
    & {
        align-items: center;
        background: color("light");
        border-radius: 50%;
        color: color("error");
        display: inline-flex;
        height: remify(32, 16);
        justify-content: center;
        padding: 0;
        width: remify(32, 16);
    }

    @include larger-than(xs) {
        margin-bottom: 0;
    }
}

.alert__user-content {
    > :last-child {
        margin-bottom: 0 !important;
    }
}
