// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: color("primary");
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        line-height: remify(26, 22);
        margin: remify(0 0 26);
        text-transform: uppercase;
    }
}

.title--banner {
    & {
        color: color("light");
        font-size: remify(24, 16);
        line-height: remify(30, 24);
        margin-bottom: 0;
        text-align: center;
        text-transform: uppercase;
    }
}

.title--h2 {
    & {
        font-size: remify(22, 16);
        line-height: remify(26, 22);
        margin-bottom: remify(23, 22);
        text-transform: none;
    }
}

.title--h3 {
    & {
        font-size: remify(20, 16);
        line-height: remify(26, 20);
        margin-bottom: remify(23, 20);
        text-transform: none;
    }
}

.title--h4 {
    & {
        color: color("secondary");
        font-size: remify(18, 16);
        line-height: remify(22, 18);
        margin-bottom: remify(20, 18);
        text-transform: none;
    }
}

.title--h5,
.title--h6 {
    & {
        color: color("foreground", "shade");
        font-size: remify(14, 16);
        font-weight: 900;
        line-height: remify(20, 14);
        margin-bottom: remify(12, 14);
        text-transform: none;
    }
}

/* divider variant */

.title--divider {
    & {
        align-items: center;
        display: flex;
    }

    &::after {
        background: color("primary", "tint");
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(3);
    }

    &::after {
        margin-left: remify(13, 22);
    }
}

@include larger-than(l) {
    .title {
        & {
            font-size: remify(36, 16);
            line-height: remify(43, 36);
            margin: remify(0 0 30);
        }
    }

    .title--banner {
        & {
            font-size: remify(50, 16);
            line-height: remify(65, 50);
            margin: 0;
        }
    }

    .title--h2 {
        & {
            font-size: remify(36, 16);
            margin: remify(0 0 30);
        }
    }

    .title--h3 {
        & {
            font-size: remify(30, 16);
            margin: remify(0 0 26);
        }
    }

    .title--h4 {
        & {
            font-size: remify(26, 16);
            line-height: remify(30, 26);
            margin-bottom: remify(16, 26);
        }
    }

    .title--h5,
    .title--h6 {
        & {
            font-size: remify(20, 16);
            line-height: remify(24, 20);
            margin-bottom: remify(16, 20);
        }
    }

    /* divider variant */

    .title--divider {
        &::after {
            height: remify(3);
        }

        &::after {
            margin-left: remify(23, 36);
        }
    }
}
