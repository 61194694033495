// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Accessible Poetry (https://wordpress.org/plugins/accessible-poetry/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-max-id, selector-no-qualifying-type */

body.acwp-monochrome {
    & {
        filter: none !important;
    }
}

#acwp-toolbar-btn-wrap {
    & {
        bottom: -100vh;
        margin-top: 0;
        top: auto;
        transition: bottom 1.2s ease, visibility 0s 1.2s;
        visibility: hidden;
    }

    &.show {
        bottom: 20px;
        margin-top: 0;
        top: auto;
        transition: bottom 1.2s ease;
        visibility: visible;
    }
}

.acwp-toolbar {
    & {
        bottom: -100vh;
        top: auto;
        transition: bottom 1.2s ease;
    }

    &.acwp-toolbar-show {
        bottom: 20px;
        top: auto;
    }
}

.acwp-toolbar {
    & {
        font-family: $body-font;
    }
}

.acwp-footer {
    & {
        display: none;
    }
}
