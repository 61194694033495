// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Columns
\* ------------------------------------------------------------------------ */

@for $i from 2 through 12 {
    .columns--#{$i} {
        columns: $i;
    }
}

.columns {
    & {
        margin-bottom: remify(60, 16);
    }

    /* stylelint-disable selector-max-type */

    ul:last-child,
    ol:last-child {
        margin-bottom: 0;
    }

    li {
        break-inside: avoid;
        margin-right: remify(42);
    }

    /* stylelint-enable selector-max-type */
}
