// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: color("accent");
        cursor: pointer;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: color("accent", "tint");
    }
}

/* inherit variant */

.link--inherit {
    & {
        color: inherit;
    }

    &:focus,
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}
