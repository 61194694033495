// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/swiper/swiper_xl";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
