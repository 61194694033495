// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }

    & {
        background-color: transparentize(color("primary", "shade"), 1);
        left: 0;
        padding: remify(0 $content-padding);
        position: fixed;
        right: 0;
        top: remify(-1);
        transition: background-color 0.15s;
    }

    .menu-list {
        transition: padding 0.15s;
    }

    @include smaller-than(xxxl) {
        .menu-list--primary {
            padding: remify(20 0, 16);
        }

        .menu-list--primary .menu-list__link {
            padding: remify(11.56 12, 16);
        }
    }

    @include smaller-than(xl) {
        .menu-list--primary .menu-list__link {
            font-size: remify(14, 16);
            padding: remify(11 8, 14);
        }
    }

    @include smaller-than(l) {
        .header__row--primary {
            height: remify(73, 16);
            transition: height 0.15s, margin 0.15s;
        }
    }
}

.header__inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.header__row--primary {
    & {
        position: relative;

        @include smaller-than(l) {
            margin-top: remify(47);
        }
    }

    &::after {
        background-color: color("light");
        content: "";
        height: 100%;
        left: -100vw;
        position: absolute;
        right: -100vw;
        top: 0;
    }
}

.header__col {
    & {
        z-index: 5;
    }
}

.header__col--logo {
    & {
        align-items: flex-end;
        display: flex;
        position: relative;
        z-index: 6;
    }
}

.header__logo {
    & {
        height: 100%;
        max-width: 100%;
        position: relative;
        transition: width 0.15s, margin 0.15s;
        width: remify(221, 16);
    }

    .logo__image {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    @include smaller-than(xxl) {
        width: remify(122, 16);
    }

    @include smaller-than(l) {
        margin: 0 auto;
        width: remify(154, 16);
    }

    @include smaller-than(xxxxs) {
        width: remify(130, 16);
    }
}

.header-block--sticky {
    & {
        background-color: color("primary", "shade");
        box-shadow: remify(0 4 8 0, 16) transparentize(color("dark"), 0.71);
    }

    .menu-list {
        padding: remify(8 0, 16);
    }

    .menu-list--toolbar {
        padding: 0;
    }

    .header__logo {
        width: remify(122, 16);
    }

    @include smaller-than(l) {
        .header__row--primary {
            height: remify(50, 16);
            margin-top: remify(35, 16);
        }
    }
}
