// Styles written by Jordan Bearce @ Weblinx, Inc.

@use "sass:math";

/* ------------------------------------------------------------------------ *\
 * Alignment
\* ------------------------------------------------------------------------ */

.aligncenter {
    & {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.alignleft {
    & {
        float: left;
        margin-right: remify(24, 16);
        max-width: math.div(100%, 3);
    }
}

.alignright {
    & {
        float: right;
        margin-left: remify(24, 16);
        max-width: math.div(100%, 3);
    }
}

.alignleft,
.alignright {
    @include smaller-than(xs) {
        display: block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
}
