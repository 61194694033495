// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    & {
        display: block;
        text-decoration: none;
        transition: opacity 0.15s;
    }
}

.logo__image {
    & {
        display: block;
        width: 100%;
    }
}
