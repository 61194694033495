// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: color("primary");
        border: 0;
        clear: both;
        height: remify(2);
        margin: remify(15 0, 16);
        width: 100%;
    }
}
