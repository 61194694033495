// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list__container {
    & {
        clear: fix;
    }
}

.menu-list {
    & {
        clear: fix;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .menu-list__link--button {
        @extend .button;

        border-radius: remify(22, 16);
        color: color("light") !important;
        line-height: remify(20, 16);
        margin-bottom: 0;
        padding: remify(4 22, 16);
        transition: background-color 0.15s;
    }

    .menu-list__link--button:hover,
    .menu-list__link--button:focus {
        color: color("light") !important;
    }
}

.menu-list__item {
    & {
        float: left;
        position: relative;
    }
}

.menu-list__link {
    & {
        display: block;
    }
}

.menu-list__toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 0 0 auto;
        font-size: 1em;
        margin: 0;
    }

    .toggle__icon {
        transition: transform 0.15s;
    }

    .menu-list__item.is-active > & .toggle__icon {
        transform: rotate(-180deg);
    }
}

/* accordion variant */

.menu-list--accordion {
    .menu-list__item--parent {
        display: flex;
        flex-wrap: wrap;
    }

    .menu-list__item--parent > .menu-list__link {
        flex: 1 1 0%;
    }
}

/* child variant */

.menu-list--child {
    & {
        min-width: 100%;
    }

    .menu-list__item {
        float: none;
    }
}

/* child accordion variant */

.menu-list--child.menu-list--accordion {
    & {
        height: 0;
        overflow: hidden;
        transition: height 0.15s, visibility 0s 0.15s !important; // @NOTE !important required to override transition-to-from-auto inline style
        visibility: hidden;
    }

    .menu-list__item--parent.is-active > & {
        transition: height 0.15s !important; // @NOTE !important required to override transition-to-from-auto inline style
        visibility: visible;
    }
}

/* child overlay variant */

.menu-list--child.menu-list--overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list__item--parent:hover > &,
    .menu-list__item--parent.is-active > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
        z-index: 1;
    }

    // must be separate from the above rules for fallback reasons

    .no-javascript .menu-list__item--parent:focus-within > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* child overlay depth 2 variant */

.menu-list--child.menu-list--overlay.menu-list--depth-2 {
    .menu-list__item--parent:hover > &,
    .menu-list__item--parent.is-active > & {
        left: 100%;
        top: 0;
    }

    // must be separate from the above rules for fallback reasons

    .no-javascript .menu-list__item--parent:focus-within > & {
        left: 100%;
        top: 0;
    }
}

/* child overlay reverse variant */

.menu-list--child.menu-list--overlay.menu-list--reverse {
    & {
        left: auto;
        right: 999em;
        transition: right 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
    }

    .menu-list__item--parent:hover > &,
    .menu-list__item--parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }

    // must be separate from the above ruleset for fallback reasons

    .no-javascript .menu-list__item--parent:focus-within > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* child overlay reverse depth 2 variant */

.menu-list--child.menu-list--overlay.menu-list--reverse.menu-list--depth-2 {
    .menu-list__item--parent:hover > &,
    .menu-list__item--parent.is-active > & {
        right: 100%;
        top: 0;
    }

    // must be separate from the above ruleset for fallback reasons

    .no-javascript .menu-list__item--parent:focus-within > & {
        right: 100%;
        top: 0;
    }
}

/* display-flex variant */

.menu-list--display-flex {
    & {
        display: flex;
    }
}

/* justify-start variant */

.menu-list--justify-start {
    & {
        justify-content: flex-start;
    }

    > .menu-list__item {
        flex: 0 0 auto;
    }
}

/* justify-center variant */

.menu-list--justify-center {
    & {
        justify-content: center;
    }

    > .menu-list__item {
        flex: 0 0 auto;
    }
}

/* justify-end variant */

.menu-list--justify-end {
    & {
        justify-content: flex-end;
    }

    > .menu-list__item {
        flex: 0 0 auto;
    }
}

/* justify-between variant */

.menu-list--justify-between {
    & {
        justify-content: space-between;
    }

    > .menu-list__item {
        flex: 0 0 auto;
    }
}

/* vertical variant */

.menu-list--vertical {
    > .menu-list__item {
        float: none;
    }

    &.menu-list--small {
        .menu-list__item {
            font-size: remify(14, 16);
        }
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* mega variant */

.menu-list__container--mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list__item--mega.menu-list__item--parent:hover > &,
    .menu-list__item--mega.menu-list__item--parent.is-active > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    // must be separate from the above ruleset for fallback reasons

    .no-javascript .menu-list__item--mega.menu-list__item--parent:focus-within > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    .menu-list.menu-list--depth-1 {
        flex: 0 0 auto;
    }

    .menu-list.menu-list--depth-2 {
        flex: 0 0 100%;
    }
}

/* mega reverse variant */

.menu-list__container--mega.menu-list--reverse { // technically not a valid class name, but it's easier this way
    & {
        left: auto;
        right: 999em;
        transition: right 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
    }

    .menu-list__item--mega.menu-list__item--parent:hover > &,
    .menu-list__item--mega.menu-list__item--parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }

    // must be separate from the above ruleset for fallback reasons

    .no-javascript .menu-list__item--mega.menu-list__item--parent:focus-within > & {
        left: auto;
        right: 0;
    }
}

/* meta variant */

.menu-list--meta {
    & {
        color: color("foreground", "tint");
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        margin: remify(0 0 14, 14);
        text-align: left;
        text-transform: uppercase;
    }

    .menu-list__item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list__link {
        color: color("foreground", "tint");
        display: inline-block;
    }

    .menu-list__link:hover {
        color: color("foreground", "tint");
    }
}

/* navigation variant */

.menu-list--navigation {
    & {
        font-family: $heading-font;
        font-size: remify(16, 16);
        font-weight: 700;
    }

    .menu-list__link,
    .menu-list__toggle {
        & {
            color: color("foreground", "tint");
            padding: remify(13 27.5, 18);
            transition: color 0.15s;
        }

        &.menu-list__link--current {
            color: color("primary");
        }
    }

    .menu-list__link--highlight {
        color: color("primary", "tint");
        font-weight: 700;
    }

    .menu-list__link--highlight:hover,
    .menu-list__link--highlight:focus,
    .menu-list__link--highlight:active {
        color: color("secondary", "tint") !important;
    }

    .menu-list__link {
        .svg-inline--fa {
            margin-left: remify(8, 18);
        }
    }

    .menu-list__item.is-active,
    .menu-list__item:focus-within,
    .menu-list__item:hover {
        > .menu-list__link,
        > .menu-list__toggle {
            color: color("primary", "tint");
        }
    }

    /* child */

    .menu-list--child {
        & {
            background: color("primary", "shade");
            font-size: remify(18, 18);
        }

        &::before,
        &::after {
            content: "";
            display: block;
            width: 100%;
        }
    }

    .menu-list--child .menu-list__link,
    .menu-list--child .menu-list__toggle {
        color: color("light");
        transition: background-color 0.15s;
    }

    .menu-list--child .menu-list__item.is-active,
    .menu-list--child .menu-list__item:focus-within,
    .menu-list--child .menu-list__item:hover {
        > .menu-list__link,
        > .menu-list__toggle {
            background-color: transparentize(color("light"), 0.8);
            color: color("light");
        }
    }

    /* child depth-2 */

    .menu-list--child.menu-list--depth-2 {
        background: darken(color("primary", "shade"), 10);
        font-family: $body-font;
        font-weight: 400;
    }

    /* mega */

    .menu-list__container--mega {
        background: color("primary", "shade");
    }

    .menu-list--child.menu-list--depth-1.menu-list--mega {
        & {
            background: none;
            min-width: 0;
            width: remify(270, 18);
        }

        &:not(:last-child) {
            border-right: remify(1) solid darken(color("primary", "shade"), 10);
        }
    }

    /* mega depth-2 */

    .menu-list--child.menu-list--depth-2.menu-list--mega {
        & {
            background: none;
        }

        &::before {
            display: none;
        }
    }

    /* overlay */

    .menu-list--overlay {
        width: remify(270, 18);
    }

    /* overlay depth-2 */

    .menu-list--overlay.menu-list--depth-2 {
        transform: translateY(#{remify(-12, 18)});
    }
}

/* pagination variant */

.menu-list--pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list__item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list__link--current {
        color: color("foreground");
        cursor: auto;
    }
}

/* submenu variant */

.menu-list--submenu {
    & {
        font-family: $body-font;
        font-size: remify(18, 16);
        font-weight: 400;
    }

    .menu-list__link {
        border-bottom: remify(2) solid color("background", "shade");
        padding: remify(9 0, 18);
    }

    .menu-list--child {
        padding: remify(4.5 0, 18);
    }

    .menu-list--child .menu-list__link {
        background-image: svg-load("../media/vendor/fas-angle-right.svg", fill=#{color("light", "shade")});
        background-position: remify(0 11, 18);
        background-repeat: no-repeat;
        background-size: remify(13.5 13.5, 18);
        border-bottom: 0;
        padding: remify(4.5 0 4.5 18, 18);
    }

    .menu-list--child.menu-list--depth-2 {
        padding-left: remify(18, 18);
    }
}

.menu-list--primary {
    & {
        padding: remify(31 0, 16);
        position: relative;
    }

    &::after {
        background-color: color("light");
        height: 100%;
        left: -100vw;
        position: absolute;
        right: -100vw;
        top: 0;
        z-index: -1;
    }

    > .menu-list__item:last-child > .menu-list__link {
        padding-right: 0;
    }
}

/* toolbar variant */
.menu-list--toolbar {
    & {
        //
    }

    .menu-list__link {
        color: color("light");
        font-size: remify(16, 16);
        font-weight: 500;
        padding: remify(13 17.5, 16);
        text-decoration: none;
    }

    .menu-list__link--highlight {
        color: color("primary", "tint");
        font-weight: 700;
    }

    .menu-list__link--highlight:hover,
    .menu-list__link--highlight:focus,
    .menu-list__link--highlight:active {
        color: color("secondary", "tint") !important;
    }

    .menu-list__link--button {
        @extend .button;

        border-radius: remify(22, 16);
        line-height: remify(20, 16);
        margin-bottom: 0;
        padding: remify(4 22, 16);
        transition: background-color 0.15s;
    }

    .menu-list__link--button:hover,
    .menu-list__link--button:focus {
        color: color("light") !important;
    }
}
