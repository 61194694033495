// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Mixins
\* ------------------------------------------------------------------------ */

@use "sass:math";

// nth-child: https://paulund.co.uk/nth-child-sass-mixins

@mixin first-child() {
    &:first-of-type {
        @content;
    }
}

@mixin last-child() {
    &:last-of-type {
        @content;
    }
}

@mixin even() {
    &:nth-child(even) {
        @content;
    }
}

@mixin odd() {
    &:nth-child(odd) {
        @content;
    }
}

@mixin first($num) {
    @if $num == 1 {
        &:first-child {
            @content;
        }
    }

    @else {
        &:nth-child(-n + #{$num}) {
            @content;
        }
    }
}

@mixin last($num) {
    &:nth-last-child(-n + #{$num}) {
        @content;
    }
}

@mixin after($num) {
    &:nth-child(n+#{$num + 1}) {
        @content;
    }
}

@mixin from-end($num) {
    &:nth-last-child(#{$num}) {
        @content;
    }
}

@mixin between($first, $last) {
    &:nth-child(n+#{$first}):nth-child(-n+#{$last}) {
        @content;
    }
}

@mixin all-but($num) {
    &:not(:nth-child(#{$num})) {
        @content;
    }
}

@mixin each($num) {
    &:nth-child(#{$num}n) {
        @content;
    }
}

// layers

@mixin layer($layer-name) {
    position: relative;
    z-index: map-get($layers, $layer-name);
}

// Scale dynamically until a given viewport

@mixin viewport-scale($size: 16, $base: 1024, $min-width: 0, $max-width: null) {
    $max-width: if($max-width == null, $base, $max-width);

    @media screen and (min-width: remify($min-width, 16)) and (max-width: remify($max-width, 16)) {
        font-size: ($size / $base) * 100vw;
    }
}

// Create a media query relative to a break point variable

@mixin smaller-than($point-name, $inclusive: false) {
    $width: map-get($breakpoints, $point-name);

    @if $inclusive == false {
        $width: $width - math.div(1, 16);
    }

    @media (max-width: $width) {
        @content;
    }
}

// Create a media query relative to a break point variable

@mixin larger-than($point-name, $inclusive: true) {
    $width: map-get($breakpoints, $point-name);

    @if $inclusive == false {
        $width: $width + math.div(1, 16);
    }

    @media (min-width: $width) {
        @content;
    }
}

// Scale font size based on VW units

@mixin font-scale($size, $point-name) {
    $width: map-get($breakpoints, $point-name);

    font-size: math.div($size, strip-unit($width * 16)) * 100vw;
}

// Full Width Containers in Limited Width Parents

@mixin full-bleed {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;
}
