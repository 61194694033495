// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

@use "sass:math";

// Custom

.swiper__title {
    & {
        font-size: remify(32, 16);
    }

    .title--small {
        font-size: remify(28, 32);
    }
}

.swiper__user-content {
    .text {
        font-size: remify(20, 16);
        margin-bottom: remify(25, 20);
    }

    .text .button {
        font-size: remify(17, 20);
        padding: remify(19 27, 17);
    }
}

.swiper-container--clients {
    .swiper__picture {
        height: remify(121, 16);
    }
}
