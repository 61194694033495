// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

@use "sass:math";

// Custom

.swiper__caption {
    & {
        padding-bottom: remify(73, 16);
        padding-top: remify(217, 16);
    }

    .swiper-container--fill-short & {
        padding-bottom: remify(70, 16);
        padding-top: remify(230, 16);
    }
}

.swiper__title {
    & {
        font-size: remify(50, 16);
        margin-bottom: remify(7, 50);
    }

    .title--small {
        font-size: remify(42, 50);
    }
}
