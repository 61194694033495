// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: color("primary", "tint");
        border: 0;
        border-radius: remify(27, 14);
        color: color("light");
        cursor: pointer;
        display: inline-block;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        line-height: remify(19.2, 14);
        margin: remify(0 0 22, 14);
        outline: 0;
        padding: remify(15 42 11, 14);
        text-align: center;
        text-decoration: none;
        transition: background-color 0.15s;
    }

    &:active {
        background-color: color("primary", "shade");
    }

    &[href]:focus,
    &[href]:hover {
        background-color: color("tertiary", "shade");
    }

    @include larger-than(l) {
        & {
            border-radius: remify(27, 17);
            font-size: remify(17, 16);
            line-height: remify(19.2, 17);
            margin: remify(0 0 22, 17);
            padding: remify(18 46 16, 17);
        }

        & + .button {
            margin-left: remify(20, 17);
        }
    }
}

.button--alt {
    & {
        background: color("secondary");
    }

    &:active {
        background-color: color("secondary", "tint");
    }

    &[href]:focus,
    &[href]:hover {
        background-color: color("secondary", "tint");
    }
}

/* disabled variant */

.button--disabled {
    & {
        cursor: not-allowed;
        opacity: 0.6;
        pointer-events: none;
    }
}
