// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

@use "sass:math";

// Vendor

@use "swiper/swiper";
@use "swiper/modules/autoplay/autoplay";
@use "swiper/modules/effect-fade/effect-fade";
@use "swiper/modules/navigation/navigation";
@use "swiper/modules/pagination/pagination";

// Custom

.swiper-container {
    & {
        overflow-x: hidden;
    }
}

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
}

.swiper__picture {
    & {
        display: flex;
        flex: none;
        inset: 0;
        position: absolute;
    }

    &::after {
        content: "";
        display: block;
        height: 100%;
        opacity: 0.67;
        width: 100%;
    }
}

.swiper-container--fill {
    .swiper__title {
        color: color("light");
        margin-bottom: remify(5, 24);
    }
}

.swiper-container--fill-short .swiper__picture {
    &::after {
        padding-bottom: remify(309, 16);
    }
}

.swiper-container--overlay .swiper__picture {
    .swiper__image {
        opacity: 0.8;
    }

    &::after {
        background-image: linear-gradient(91deg, color("dark") 28%, color("secondary", "shade") 53%);
    }
}

.swiper__image {
    & {
        background-position: center;
        display: block;
        height: 100%;
        left: 0;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.swiper-container--fill .swiper__image {
    & {
        object-fit: cover;
    }
}

.swiper__caption {
    & {
        flex: 1 1 auto;
        height: auto;
        left: 0;
        padding: remify(200 $content-padding 61, 16);
        position: relative;
        right: 0;
        z-index: 3;
    }

    .swiper-container--fill-short & {
        margin-bottom: remify(28, 16);
    }
}

.swiper__caption__inner {
    & {
        margin: 0 auto;
        max-width: remify($slideshow-width);
    }
}

.swiper__title {
    & {
        color: color("primary", "tint");
        font-size: remify(24, 16);
        font-weight: 700;
        margin-bottom: remify(13, 24);
        text-transform: uppercase;
    }

    &,
    &.title--small {
        display: block;
        text-align: center;
    }

    .title--small {
        color: color("light");
        font-size: remify(18, 24);
        text-transform: none;
    }
}

.swiper__user-content {
    > :last-child {
        margin-bottom: 0 !important;
    }

    * {
        text-align: center;
    }

    .text {
        font-size: remify(14, 16);
        margin-bottom: remify(26, 14);
    }

    .text .button {
        font-size: remify(14, 14);
        padding: remify(15 43, 14);
    }
}

.swiper-button {
    & {
        align-items: center;
        background: none;
        border: 0;
        color: color("primary");
        cursor: pointer;
        display: flex;
        font-size: remify(36, 16);
        height: 100%;
        justify-content: center;
        margin: 0;
        opacity: 1;
        padding: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.15s;
        width: remify(50, 50);
        z-index: 999;
    }

    &.swiper-button--prev,
    .swiper-container-rtl &.swiper-button--next {
        left: 0;
    }

    &.swiper-button--next,
    .swiper-container-rtl &.swiper-button--prev {
        right: 0;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        opacity: 0.5;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize(color("dark"), 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background-color 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background-color: color("primary");
    }
}

.swiper-container--clients {
    .swiper__picture {
        height: remify(85, 16);
        position: relative;
        width: auto;
    }
}
