// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/columns/columns_xs";
@import "../module/swiper/swiper_xs";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
