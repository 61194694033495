// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

:root {
    & {
        accent-color: color("accent");
    }
}

html,
body {
    & {
        background: color("page-background");
        min-height: 100vh;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        width: 100%;
    }
}

.page__container {
    & {
        background: color("page-background");
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow: hidden; // will interfere with `position: sticky;`
        overflow: clip; // will NOT interfere with `position: sticky;`
        position: relative;
        width: 100%;
    }

    #page-spacer { // stylelint-disable-line
        height: 0;
        opacity: 0;
        padding: 0;
    }

    #content { // stylelint-disable-line
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    .content-block {
        flex: 1 0 auto;
    }
}
