// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * iframe
\* ------------------------------------------------------------------------ */

.iframe__container {
    & {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
    }

    > iframe { // stylelint-disable-line
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}
