// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Helpers
\* ------------------------------------------------------------------------ */

// imports

@import "_mixins";
@import "_functions";
@import "_variables";

// generic helpers

.__dark {
    color: color("dark") !important;
}

.__light {
    color: color("light") !important;
}

.__big {
    font-size: 1.25em !important;
}

.__small {
    font-size: 0.75em !important;
}

.__bold {
    font-weight: 700 !important;
}

.__italic {
    font-style: italic !important;
}

.__underline {
    text-decoration: underline !important;
}

.__lowercase {
    text-transform: lowercase !important;
}

.__uppercase {
    text-transform: uppercase !important;
}

.__textcenter {
    text-align: center !important;
}

.__textleft {
    text-align: left !important;
}

.__textright {
    text-align: right !important;
}

.__nowrap {
    white-space: nowrap !important;
}

.__center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.__left {
    float: left !important;
}

.__right {
    float: right !important;
}

.__nomargin {
    margin: 0 !important;
}

.__nopadding {
    padding: 0 !important;
}

.__notransform {
    transform: none !important;
}

.__notransition {
    transition: none !important;
}

.__block {
    display: block !important;
}

.__flex {
    display: flex !important;
}

.__inline {
    display: inline !important;
}

.__visuallyhidden {
    left: -999em !important;
    position: absolute !important;
    top: -999em !important;
}

.__primary {
    color: color("primary") !important;
}

.__accent {
    color: color("accent") !important;
}

.__error {
    color: color("error") !important;
}

/* "visible" classes */

@each $name, $size in $breakpoints {
    @include smaller-than($name) {
        .__visible-#{$name} {
            display: none !important;
        }
    }
}

/* "hidden" classes -- NOTE: It is important that __hidden classes come after __visible for override purposes */

@each $name, $size in $breakpoints {
    @include larger-than($name) {
        .__hidden-#{$name} {
            display: none !important;
        }
    }
}
