// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * WP Caption
\* ------------------------------------------------------------------------ */

.wp-caption {
    & {
        margin-bottom: 0;
        margin-top: 0;
        max-width: 100%;
    }

    &:not(.alignleft) {
        margin-right: 0;
    }

    &:not(.alignright) {
        margin-left: 0;
    }
}

.wp-caption-image {
    & {
        display: block;
        width: 100%;
    }

    &:last-child {
        margin-bottom: remify(24, 16);
    }
}

.wp-caption-text {
    & {
        color: color("foreground");
        font-family: $body-font;
        font-size: remify(16, 16);
        font-style: italic;
        line-height: remify(28, 16);
        margin: remify(14 0 28, 16);
    }
}
