// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

@use "sass:color";

.navigation-block {
    & {
        @include layer("navigation");
    }

    & {
        background: color("background");
    }
}

.navigation__inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
        max-width: calc(#{remify($site-width)} + #{remify(16 * 2, 20)});
    }
}

.navigation__figure {
    & {
        margin: remify(0 0 8, 16);
    }
}

.navigation__image {
    & {
        display: block;
        width: 100%;
    }
}

/* flyout variant */

.navigation-block--flyout {
    & {
        background: none;
        bottom: 999em;
        left: -999em;
        position: fixed;
        top: -999em;
        transition: bottom 0s 0.25s, left 0s 0.25s, top 0s 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100vw;
        z-index: 100001;
    }

    .navigation__inner {
        background: color("background");
        height: 100%;
        margin: 0;
        overflow: auto;
        overscroll-behavior: contain;
        padding: remify(13 0 0 0);
        position: relative;
        transform: translateX(#{remify(-280, 16)});
        transition: transform 0.25s;
        width: remify(280, 16);
        z-index: 2;
    }

    .menu-list--small .navigation__inner {
        padding: remify(11 0 0 0);
    }

    .navigation__overlay {
        -webkit-appearance: none; // stylelint-disable-line
        -moz-appearance: none; // stylelint-disable-line
        appearance: none;
        background: color.adjust(color("dark"), $alpha: -0.4);
        border: 0;
        content: "";
        cursor: pointer;
        display: block;
        height: 100%;
        left: -999em;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.25s, top 0s 0.15s, opacity 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
        z-index: 1;
    }

    .menu-active & {
        bottom: 0;
        left: 0;
        top: 0;
        transition: none;
        visibility: visible;
    }

    .menu-active & .navigation__inner {
        transform: translateX(0);
    }

    .menu-active & .navigation__overlay {
        left: 0;
        opacity: 1;
        top: 0;
        transition: opacity 0.25s;
        visibility: visible;
    }

    .menu-list__link--button {
        display: block;
        font-size: remify(16, 16);
        margin: remify(0 22 11.556, 16);
    }
}

/* toolbar variant */

.navigation-block--toolbar {
    & {
        background: none;
    }

    .menu-list {
        z-index: 100;
    }
}
