// Styles written by Jordan Bearce @ Weblinx, Inc.

@use "sass:math";

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$colors: (
    "primary": (
        tint:  #279FFC,
        base:  #0171BC,
        shade: #14213A,
    ),
    "secondary": (
        tint:  #749DC2,
        base:  #004B8F,
        shade: #0C2036,
    ),
    "tertiary": (
        tint:  lighten(#DDEFFF, 10%),
        base:  #DDEFFF,
        shade: #9FC9F9,
    ),
    "quaternary": (
        tint:  #FFFF5A,
        base:  #FFFF00,
        shade: #C7CC00,
    ),
    "quinary": (
        tint:  #B47CFF,
        base:  #7C4DFF,
        shade: #3F1DCB,
    ),
    "senary": (
        tint:  #FFC246,
        base:  #FF9100,
        shade: #C56200,
    ),
    "accent": (
        tint:  #83B9FF,
        base:  #448AFF,
        shade: #005ECB,
    ),
    "error": (
        tint:  #FF5F52,
        base:  #C62828,
        shade: #8E0000,
    ),
    "success": (
        tint:  #52FFA2,
        base:  #28C665,
        shade: #008E37,
    ),
    "foreground": (
        tint:  #434343,
        base:  #232323,
        shade: #1B1B1B,
    ),
    "background": (
        tint:  #FFFFFF,
        base:  #FFFFFF,
        shade: #CCCCCC,
    ),
    "page-background": (
        tint:  #EEEEEE,
        base:  #E0E0E0,
        shade: #F7F7F7,
    ),
    "light": (
        tint:  #FFFFFF,
        base:  #FFFFFF,
        shade: #CCCCCC,
    ),
    "dark": (
        tint:  #2C2C2C,
        base:  #000000,
        shade: #000000,
    ),
);

/* ------------------------------------------------------------------------ *\
 * Colors (Custom Properties)
\* ------------------------------------------------------------------------ */

:root {
    @each $name, $data in $colors {
        @each $variant, $hex in $data {
            --sgt-color-#{$name}-#{$variant}: #{$hex}; // stylelint-disable-line
        }
    }
}

/* ------------------------------------------------------------------------ *\
 * Fonts
\* ------------------------------------------------------------------------ */

$heading-font: "Montserrat", "Arial", sans-serif;
$body-font: "Montserrat", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths
\* ------------------------------------------------------------------------ */

$site-width: 1440;
$content-width: 1263;
$slideshow-width: 1100;
$content-slideshow-width: 1260;
$callout-width: 1266;
$footer-width: 1080;
$column-gap: 30;
$content-padding: 25;

/* ------------------------------------------------------------------------ *\
 * Column Count
\* ------------------------------------------------------------------------ */

$column-count: 12;

/* ------------------------------------------------------------------------ *\
 * Break Points
\* ------------------------------------------------------------------------ */

$xxxl-break: math.div(1440em, 16);
$xxl-break:  math.div(1366em, 16);
$xl-break:   math.div(1280em, 16);
$l-break:    math.div(1024em, 16);
$m-break:    math.div(960em, 16);
$s-break:    math.div(853em, 16);
$xs-break:   math.div(768em, 16);
$xxs-break:  math.div(640em, 16);
$xxxs-break: math.div(480em, 16);
$xxxxs-break: math.div(350em, 16);

$breakpoints: (
    xxxxs: $xxxxs-break,
    xxxs: $xxxs-break,
    xxs:  $xxs-break,
    xs:   $xs-break,
    s:    $s-break,
    m:    $m-break,
    l:    $l-break,
    xl:   $xl-break,
    xxl:  $xxl-break,
    xxxl: $xxxl-break,
);

/* ------------------------------------------------------------------------ *\
 * Layers
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
