// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }

    & {
        background: color("background");
        padding: remify(($content-padding * 3.2) $content-padding);
        position: relative;
    }

    @include larger-than(l) {
        & {
            padding: remify(($content-padding * 3.2) $content-padding ($content-padding * 4.56));
        }
    }
}

.content-block--home {
    & {
        padding-bottom: remify(($content-padding * 3.2));
    }

    h2 { // stylelint-disable-line
        margin-bottom: remify(20);
    }

    @include larger-than(l) {
        h2 { // stylelint-disable-line
            margin-bottom: remify(18);
        }

        & {
            padding-bottom: remify(($content-padding * 3.32));
        }
    }
}

.content__inner {
    & {
        margin: 0 auto;
        max-width: remify($content-width);
        position: relative;
        z-index: 3;
    }
}

.content-block--background {
    & {
        padding: remify(($content-padding * 3.2) $content-padding);
    }

    @include larger-than(l) {
        padding: remify(($content-padding * 4.16) $content-padding);
    }

    &::after {
        background: color("primary", "shade");
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.79;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    /* stylelint-disable selector-max-type */
    p {
        font-size: remify(14, 16);
        margin-bottom: remify(19, 14);
    }

    p a {
        font-size: remify(14, 14);
    }

    a,
    .button {
        padding: remify(14 54 10, 14);
    }

    a:not(.button) {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        text-transform: none;
    }

    @include larger-than(l) {
        p,
        a {
            font-size: remify(20, 16);
            margin-bottom: remify(19, 14);
        }

        a {
            padding-bottom: remify(15, 20);
            padding-top: remify(15, 20);
        }

        a:not(.button) {
            display: block;
            line-height: remify(22.59, 20);
            padding-left: 0;
            padding-right: 0;
        }
    }
    /* stylelint-enable selector-max-type */
}

.content__picture {
    & {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .content__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.content-block--internal {
    @include smaller-than(l) {
        padding: remify(($content-padding * 3.2) $content-padding ($content-padding * 1.56));
    }
}

.content-block--swiper {
    .content__inner {
        max-width: remify($content-slideshow-width);
    }

    .content__swiper__title {
        font-size: remify(22, 16);
        line-height: remify(26, 22);
        text-align: center;
    }

    .content__swiper-container {
        padding-left: remify(36);
        padding-right: remify(36);
        position: relative;
    }

    .content__swiper-container::before,
    .content__swiper-container::after {
        background: color("light");
        bottom: 0;
        content: "";
        position: absolute;
        top: 0;
        width: remify(36);
        z-index: 10;
    }

    .content__swiper-container::before {
        left: 0;
    }

    .content__swiper-container::after {
        right: 0;
    }

    .swiper-button--prev,
    .swiper-button--next {
        z-index: 11;
    }

    .swiper-button--prev {
        justify-content: flex-start;
        left: remify(0);
    }

    .swiper-button--next {
        justify-content: flex-end;
        right: remify(0);
    }

    @include larger-than(l) {
        & {
            padding-bottom: remify(($content-padding * 5.5));
            padding-top: remify(($content-padding * 4));
        }

        .content__swiper-container {
            padding-left: remify(115);
            padding-right: remify(115);
        }

        .content__swiper-container::before,
        .content__swiper-container::after {
            width: remify(115);
        }

        .content__swiper__title {
            font-size: remify(36, 16);
            line-height: remify(43, 36);
            margin-bottom: remify(47, 36);
        }
    }
}
