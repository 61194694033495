// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: color("foreground");
        display: block;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        line-height: remify(20, 14);
        margin: remify(0 0 23, 14);
    }

    @include larger-than(l) {
        font-size: remify(20, 16);
        line-height: remify(26, 20);
        margin: remify(0 0 26, 20);
    }

    .text {
        font-size: 1em;
    }

    > .button:last-child {
        margin-bottom: 0 !important;
    }
}

/* label variant */

.text--label {
    & {
        cursor: pointer;
        font-weight: 600;
    }
}

/* list variant */

.text--list {
    & {
        padding: 0;
    }

    .text__list-item {
        display: list-item;
    }
}

/* list ordered variant */

.text--list-ordered {
    & {
        list-style: decimal;
    }
}

.text--list-checked {
    & {
        list-style: none;
    }

    .text__list-item {
        position: relative;
    }

    .text__list-item::before {
        background-image: svg-load("../media/vendor/fas-check.svg", fill=#{color("primary", "tint")});
        background-size: contain;
        content: "";
        height: remify(22);
        left: 0;
        position: absolute;
        top: remify(3);
        width: remify(18);
    }
}

/* list unordered variant */

.text--list-unordered {
    & {
        list-style: disc;
    }
}

/* table variant */

.text__table__container,
.wp-block-table,
.table__container {
    & {
        margin: 0;
        overflow: auto;
        width: 100%;
    }
}

.text--table {
    & {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    .text__header {
        background-color: color("primary", "shade");
        display: table-header-group;
    }

    .text__body {
        display: table-row-group;
    }

    .text__footer {
        color: color("foreground", "tint");
        display: table-footer-group;
        font-style: italic;
    }

    .text__row {
        display: table-row;
    }

    .text__cell {
        border: 0;
        min-width: remify(175);
        padding: remify(10 15, 16);
        text-align: left;
        vertical-align: top;
    }

    .text__header .text__cell {
        color: color("primary", "tint");
        font-size: remify(14, 14);
        padding: remify(16 27 5);
    }

    .text__header .text__row:first-child .text__cell:first-child {
        border-top-left-radius: remify(10);
    }

    .text__header .text__row:first-child .text__cell:last-child {
        border-top-right-radius: remify(10);
    }

    .text__header .text__cell,
    .text__body .text__cell--heading {
        font-weight: 700;
    }

    .text__body .text__cell {
        font-size: remify(12, 14);
        padding: remify(17 27 8);
    }

    .text__body .text__cell--heading {
        text-align: left;
    }

    > .text__row:nth-child(even) .text__cell,
    .text__body .text__row:nth-child(even) .text__cell {
        background: color("page-background", "shade");
    }

    > .text__row:nth-child(even) .text__cell:first-child,
    .text__body .text__row:nth-child(even) .text__cell:first-child {
        border-bottom-left-radius: remify(10);
        border-top-left-radius: remify(10);
    }

    > .text__row:nth-child(even) .text__cell:last-child,
    .text__body .text__row:nth-child(even) .text__cell:last-child {
        border-bottom-right-radius: remify(10);
        border-top-right-radius: remify(10);
    }
}

@include larger-than(l) {
    .text--table {
        .text__header .text__cell {
            font-size: remify(18, 20);
            padding: remify(15 27 13);
        }

        .text__body .text__cell {
            font-size: remify(16, 20);
            padding: remify(17 27 16);
        }
    }
}
