// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/callout/callout-l";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
