// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .title;

        *:not(img) + & {
            margin-top: remify(60);
        }

        @include larger-than(xs) {
            *:not(img) + & {
                margin-top: remify(34);
            }
        }
    }

    h2 {
        @extend .title--h2;

        text-transform: none;
    }

    h3 {
        @extend .title--h3;
    }

    h4 {
        @extend .title--h4;
    }

    h5 {
        @extend .title--h5;
    }

    h6 {
        @extend .title--h6;
    }

    a:not(.button) {
        @extend .link;
    }

    p,
    ol,
    ul,
    table,
    .iframe__container {
        @extend .text;
    }

    &::after {
        clear: both;
        content: "";
        display: block;
    }

    // tel links

    a:not(.button) {
        @extend .link;
    }

    // images

    img:not(.aligncenter):not(.alignleft):not(.alignright) {
        display: inline-block;
    }

    img {
        height: auto !important;
        margin-bottom: remify(60);
        max-width: 100%;
        width: 100%;
    }

    p,
    ol,
    ul {
        @extend .text;
    }

    ol,
    ul {
        @extend .text--list;

        margin-bottom: remify(60);
    }

    ul {
        @extend .text--list-unordered;

        list-style: none;
    }

    ol {
        @extend .text--list-ordered;

        padding-left: remify(16);
    }

    li {
        @extend .text__list-item;
    }

    li:not(:last-child) {
        margin-bottom: remify(10, 14);
    }

    ul li {
        padding-left: remify(32);
        position: relative;
    }

    ol li {
        padding-left: remify(18);
    }

    li::before,
    li::marker {
        color: color("primary", "tint");
        font-weight: 700;
    }

    ul li::before {
        background-image: svg-load("../media/vendor/fas-check.svg", fill=#{color("primary", "tint")});
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: remify(12);
        left: 0;
        position: absolute;
        top: remify(3);
        width: remify(13);
    }

    figure {
        margin: remify(0 0 30);
        position: relative;
    }

    figure img {
        margin-bottom: remify(10);
    }

    figcaption {
        bottom: 0;
        left: 0;
        padding: remify(8);
        position: absolute;
        width: 100%;
    }

    figcaption p {
        color: color("light");
        margin: 0;
        text-align: right;
        text-shadow: remify(0 0 8) color("dark");
    }

    .table__container {
        &:not(:first-child) {
            margin-top: remify(60);
        }

        @include larger-than(xs) {
            &:not(:first-child) {
                margin-top: remify(34);
            }
        }
    }

    table {
        @extend .text--table;
    }

    table thead {
        @extend .text__header;
    }

    table tr {
        @extend .text__row;
    }

    table th,
    table td {
        @extend .text__cell;
    }

    table th {
        @extend .text__cell--heading;
    }

    table tbody {
        @extend .text__body;
    }

    table tfoot {
        @extend .text__footer;
    }

    blockquote {
        background-color: color("page-background", "shade");
        border-radius: remify(10);
        margin: remify(0 0 23);
        padding: remify(61 33 23);
    }

    blockquote:not(:first-child) {
        margin-top: remify(70);
    }

    p .button,
    li .button,
    table .button {
        font-size: remify(16, 16);
    }

    @include larger-than(l) {
        ul li {
            padding-left: remify(40);
        }

        ol {
            padding-left: remify(22);
        }

        ol li {
            padding-left: remify(18);
        }

        ul li::before {
            height: remify(22);
            top: remify(3);
            width: remify(18);
        }

        li:not(:last-child) {
            margin-bottom: remify(12, 20);
        }

        img {
            width: auto;
        }

        p .button,
        li .button {
            font-size: remify(17, 20);
        }

        table .button {
            font-size: remify(17, 16);
        }
    }

    /* stylelint-enable */
}

/* light variant */

.user-content--light {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */

    h1,
    h3,
    h4,
    h5,
    h6 {
        color: color("light");
    }

    h2 {
        color: color("primary", "tint");
    }

    p,
    td,
    tbody th, {
        color: color("light");
    }

    a:not(.button) {
        color: color("primary", "tint");
    }

    td {
        background: none;
    }

    /* stylelint-enable */
}
