// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    & {
        background: color("primary", "shade");
        padding: remify(($content-padding * 2.8) $content-padding ($content-padding * 0.36));
    }
}

.footer__inner {
    & {
        margin: 0 auto;
        max-width: remify($footer-width);
    }

    .footer__title,
    .footer__text {
        font-size: remify(16, 16);
        line-height: remify(26, 16);
    }

    .footer__title {
        font-weight: 700;
        margin-bottom: 0;
    }

    .title--h4 {
        color: color("primary", "tint");
    }

    .title--h5 {
        color: color("light");
        text-transform: none;
    }

    .footer__text {
        color: color("light");
        font-size: remify(16, 16);
    }

    .footer__link {
        color: color("light");
        font-weight: 400;
    }

    .footer__link:hover,
    .footer__link:active {
        color: color("primary");
        text-decoration: none;
    }

    .button {
        background-color: color("primary");
        font-size: remify(16, 16);
        font-weight: 400;
    }

    @include smaller-than(l) {
        .footer__text,
        .footer__title {
            text-align: center;
        }

        .footer__title {
            margin-bottom: remify(4, 14);
        }

        .footer__text {
            font-size: remify(14, 16);
            line-height: remify(21, 14);
        }

        .title--h5 {
            font-size: remify(14, 16);
        }

        .button {
            font-size: remify(14, 14);
        }

        .footer__col {
            margin-bottom: remify(13);
        }
    }
}

.footer__logo {
    & {
        margin-bottom: remify(-36, 16);
        margin-top: remify(6, 16);
        max-width: 100%;
        width: remify(288, 16);
    }

    @include smaller-than(xs) {
        margin: remify(0 auto 27, 16);
        width: remify(184, 16);
    }
}
