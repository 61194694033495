// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Handorgel (https://www.npmjs.com/package/handorgel)
\* ------------------------------------------------------------------------ */

// Vendor

@use "handorgel/src/scss/style";

// Custom

.handorgel {
    border: none;
}

.handorgel__header {
    & {
        margin-bottom: 0 !important;
    }

    .handorgel__icon {
        transition: transform 0.15s;
    }

    &.handorgel__header--opened .handorgel__icon {
        transform: rotate(-90deg);
    }
}
