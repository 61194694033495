// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Blockquote
\* ------------------------------------------------------------------------ */

.blockquote {
    & {
        border-left: remify(2) solid color("accent");
        margin: 0;
        padding: remify(0 0 0 15, 16);
    }
}
