// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article__figure {
    & {
        margin: remify(0 0 30, 16);
    }
}

.article__figure__image {
    & {
        display: block;
        width: 100%;
    }
}

/* post-excerpt variant */

.article--post-excerpt {
    & + & {
        border-top: remify(1) solid color("background", "shade");
        padding-top: remify(16, 16);
    }

    .article__title {
        font-size: remify(18, 16);
    }

    .article__header > .article__title:not(:last-child) {
        margin-bottom: 0;
    }

    .article__header > .article__title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}
