// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Panel Toggle
\* ------------------------------------------------------------------------ */

.panel-toggle__wrapper {
    & {
        height: remify(40, 16);
        width: remify(40, 16);
    }
}

.panel-toggle {
    & {
        align-items: center;
        background: none;
        border: 0;
        color: color("dark");
        cursor: pointer;
        display: flex;
        font-size: remify(26, 16);
        height: remify(40, 24);
        justify-content: center;
        margin: 0;
        padding: 0;
        position: relative;
        width: remify(40, 24);
    }
}
