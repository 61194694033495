// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    padding-top: remify(30, 16);
}

.callout__col {
    & {
        padding-bottom: remify(29, 16);
    }
}

.callout__single {
    .callout__inner {
        padding: remify(35, 16);
    }

    .callout__hover {
        padding: remify(35 20 8, 16);
    }

    .callout__image {
        height: remify(80, 16);
    }

    .callout__title {
        font-size: remify(20, 16);
    }
}
