// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    & {
        background-color: color("background");
    }
}

.callout__col {
    & {
        height: 100%;
        padding-bottom: remify(17, 16);
    }

    @include smaller-than(xs) {
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.callout__inner {
    & {
        margin: 0 auto;
        max-width: remify($callout-width);
    }
}

.callout__single {
    & {
        border: remify(2, 16) solid color("tertiary");
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .callout__inner {
        padding: remify(30 25 25, 16);
    }

    .callout__inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 100%;
        max-width: 100%;
        overflow-y: auto;
        position: relative;
        z-index: 1;
    }

    .callout__hover {
        background-color: color("tertiary");
        height: 100%;
        left: 0;
        overflow-y: auto;
        padding: remify(30 20 8, 16);
        position: absolute;
        right: 0;
        top: 100%;
        transition: transform 0.15s;
        z-index: 2;
    }

    &:hover .callout__hover {
        transform: translateY(-100%);
    }

    .callout__image {
        height: remify(58, 16);
        margin: remify(0 auto 22, 16);
        width: auto;
    }

    .callout__title {
        color: color("primary");
        font-size: remify(16, 16);
        margin: 0;
        text-align: center;
        text-transform: none;
    }

    .callout__text {
        text-align: center;
    }
}
