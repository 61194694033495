// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * PhotoSwipe (http://photoswipe.com/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@use "photoswipe/dist/photoswipe.css";
@use "photoswipe/dist/default-skin/default-skin.css";

// Custom

.pswp {
    & {
        z-index: 100000;
    }
}

.pswp__button,
.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
    & {
        background-image: url("../media/vendor/photoswipe-default-skin.png");
    }
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
    & {
        background: none;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) { // stylelint-disable-line
    .pswp--svg .pswp__button,
    .pswp--svg .pswp__button--arrow--left::before,
    .pswp--svg .pswp__button--arrow--right::before {
        background-image: svg-load("../media/vendor/photoswipe-default-skin.svg");
    }

    .pswp--svg .pswp__button--arrow--left,
    .pswp--svg .pswp__button--arrow--right {
        background: none;
    }
}

.pswp__preloader--active .pswp__preloader__icn {
    & {
        background-image: url("../media/vendor/photoswipe-preloader.gif");
    }
}
