// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Selection
\* ------------------------------------------------------------------------ */

::selection {
    & {
        background-color: color("accent");
        color: color("light");
    }
}
