// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * ScrollHint (https://appleple.github.io/scroll-hint/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@use "scroll-hint/scss/scroll-hint";

// Custom

.scroll-hint-icon-wrap {
    & {
        font-family: $body-font;
        overflow: hidden; // fixes issue with tables that are too short
    }
}
